import './import-calendar.scss';
import { atcb_init } from "add-to-calendar-button";

import React, { useEffect } from 'react';
import 'add-to-calendar-button/assets/css/atcb.css';


// function to get time in format HH:MM from date object
function getTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (minutes < 10) minutes = '0' + minutes;
    return hours + ':' + minutes;
}

// function to get date in format YYYY-MM-DD from date object
function getDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    return year + '-' + month + '-' + day;
}

export default function ImportCalendar({ dates }) {
    console.log(dates.length)

    // create a dates array for use in the import system
    const datesArray = [];

    // loop through the dates
    for (let i = 0; i < dates.length; i++) {

        const startDate = new Date(dates[i].startTime);
        const endDate = new Date(dates[i].endTime);

        console.log(startDate);
        console.log(endDate);

        // create a date object
        const date = {
            name: dates[i].dateTitle,
            startDate: getDate(startDate),
            endDate: getDate(endDate),
            startTime: getTime(startDate),
            endTime: getTime(endDate),
            location: dates[i].dateLocation,
            description: dates[i].dateDescription,
        };

        // push the date object to the dates array
        datesArray.push(date);
    }


    React.useEffect(() => { atcb_init() }, [dates]);
    return (
        <div className="atcb" style={{ display: "none" }}>
            {'{'}
            "name":"Art of Bonsai Club Events",
            "dates": {JSON.stringify(datesArray)},
            "location": "World Wide Web",
            "label":"Import Events to Personal Calendar",
            "options":[
            "Apple",
            "Google",
            "iCal",
            "Microsoft365",
            "Outlook.com",
            "Yahoo"
            ],
            "timeZone":"US/Eastern",
            "iCalFileName":"Multi-Date-Event"
            {'}'}
        </div>
    );
}