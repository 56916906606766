import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import App.scss
import './App.scss';

import Home from './Home/home.js'
import Application from './Application/application.js'
import Dash from './Dash/dash.js';
import Schedule from './Schedule/schedule';

import RestClientProvider from './Contexts/rest_client.js'

// setup react pages for home
const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={
          <RestClientProvider>
            <Home />
          </RestClientProvider>
        } />
        <Route path="/application" element={
          <RestClientProvider>
            <Application />
          </RestClientProvider>
        } />
        <Route path="/dash" element={
          <RestClientProvider>
            <Dash />
          </RestClientProvider>
        } />
        <Route path="/schedule" element={
          <RestClientProvider>
            <Schedule />
          </RestClientProvider>
        } />
      </Routes>
    </Router>
  )
}

export default App;