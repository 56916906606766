import './calendar.scss';

// import react
import { useState, useEffect, useContext } from 'react';

// import the rest client
import { RestClientContext } from '../../../../../Contexts/rest_client.js';

export default function Calendar({ events, setCreatingEventDay, setCreatingEvent, setEventId, setGroupCriteria, groupCriteria }) {
    const current = new Date();

    // get rest client
    const { restClient } = useContext(RestClientContext);

    // state for the groups
    const [groups, setGroups] = useState([]);

    // useEffect
    useEffect(() => {
        (async () => {
            if (!restClient.user) return;
            const result = await restClient.get('/Groups/GetGroups', {}, true);
            if (result.ok) setGroups(result.data);
        })();
    }, [restClient, events]);

    const [year, setYear] = useState(current.getFullYear());
    const [month, setMonth] = useState(current.getMonth());

    const selected = new Date(year, month, 1);

    // get the name of the current month
    const selectedMonthName = selected.toLocaleString('default', { month: 'long' });

    // function to move the current month back one
    const prevMonth = () => {
        // if the month is january, move to december of the previous year
        if (month === 0) {
            setMonth(11);
            setYear(year - 1);
        } else {
            setMonth(month - 1);
        }
    }

    // function to move the current month forward one
    const nextMonth = () => {
        // if the month is december, move to january of the next year
        if (month === 11) {
            setMonth(0);
            setYear(year + 1);
        } else {
            setMonth(month + 1);
        }
    }

    // select handler
    const selectHandler = (e) => {
        setGroupCriteria(e.target.value);
    }

    // Find the first day of the month
    const firstDay = new Date(year, month, 1);

    // Determine the day of the week for the first day of the month
    const dayOfWeek = firstDay.getDay();

    // Calculate the number of blank spaces needed at the beginning of the calendar
    const blankSpaces = dayOfWeek === 0 ? 0 : dayOfWeek;

    return (
        <div className="calendar">
            <div className="calendar-header">
                <div className="calendar-header-month">{selectedMonthName}</div>
                <div className="calendar-header-year">{year}</div>
            </div>
            <div className='nav'>
                <div>
                    <span className="calendar-text-button" onClick={prevMonth}>{"Previous"}</span>
                    <span className="calendar-text-button" onClick={nextMonth}>{"Next"}</span>
                </div>
                <div>
                    <select className="custom-select" onChange={selectHandler} value={groupCriteria}>
                        <option value="0">All Groups</option>
                        {
                            groups.map(group => {
                                return (
                                    <option key={group._id} value={group._id}>{group.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="calendar-body">
                <div className="calendar-body-weekdays">
                    <div className="calendar-body-weekdays-day">Sun</div>
                    <div className="calendar-body-weekdays-day">Mon</div>
                    <div className="calendar-body-weekdays-day">Tue</div>
                    <div className="calendar-body-weekdays-day">Wed</div>
                    <div className="calendar-body-weekdays-day">Thu</div>
                    <div className="calendar-body-weekdays-day">Fri</div>
                    <div className="calendar-body-weekdays-day">Sat</div>
                </div>
                <div className="calendar-body-days">
                    {
                        // create blank days
                        new Array(blankSpaces).fill(0).map((_, i) => {
                            return (
                                <div className="calendar-body-days-day" key={i}></div>
                            )
                        })
                    }
                    {
                        // loop through each day in this month and create a day divider
                        // for each day
                        new Array(new Date(year, month + 1, 0).getDate()).fill(0).map((_, i) => {

                            // create a date object for this day at 3 pm
                            const createDate = new Date(year, month, i + 1, 15);

                            function createEvent() {
                                setCreatingEventDay(createDate);
                                setCreatingEvent(true);
                            }

                            // find all events this day
                            const eventsThisDay = events.filter(event => {
                                const eventDate = new Date(event.startTime);
                                return eventDate.toDateString() === createDate.toDateString();
                            });



                            return (
                                <div className="calendar-body-days-day" onClick={createEvent}>
                                    <div className='interior'>
                                        {
                                            // check if this is today
                                            (new Date(year, month, i + 1).toDateString() === current.toDateString()) ?
                                                <div className='day today'>{i + 1}</div> :
                                                <div className='day'>{i + 1}</div>
                                        }
                                        <div className='dates'>
                                            {
                                                eventsThisDay.map(event => {
                                                    const readibleHoursAndMinutes = new Date(event.startTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
                                                    const style = {}

                                                    if (!event.dateGroup) style.background = '#3c00ff';
                                                    else style.backgroundColor = '#9d00ff';

                                                    // onclick function that stops click passthrough and sets the event id
                                                    function onclick(e) {
                                                        e.stopPropagation();
                                                        setEventId(event._id);
                                                    }

                                                    return (
                                                        <div className='date' onClick={onclick} style={style}>
                                                            <div className='date-time'>{readibleHoursAndMinutes}</div>
                                                            <div className='date-title'>{event.dateTitle}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
