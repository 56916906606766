import './calendar.scss';

// import react stuff
import React, { useState, useEffect, useContext } from 'react';

// import the rest client
import { RestClientContext } from '../../../../Contexts/rest_client.js';


import CreatingEvent from './CreatingEvent/creating-event.js';
import EditingEvent from './EditingEvent/editing-event';
import Calendar from './Calendar/calendar';

export default function CalendarPage() {
    const { restClient } = useContext(RestClientContext);

    const [events, setEvents] = useState([]);

    const [creatingEvent, setCreatingEvent] = useState(false);
    const [creatingEventDay, setCreatingEventDay] = useState(new Date());
    const [eventId, setEventId] = useState(null);
    const [groupCriteria, setGroupCriteria] = useState("0");

    let filteredEvents = events;

    if (groupCriteria !== "0") {
        // filter events id's by criteria
        filteredEvents = filteredEvents.filter(event => event.dateGroup === groupCriteria);
    }

    useEffect(() => {
        (async () => {
            if (!restClient.user) return;
            const result = await restClient.get('/Dates/GetAllDates', {}, true);
            if (result.ok) setEvents(result.data);
        })();
    }, [restClient, creatingEvent, eventId]);

    if (creatingEvent) return <CreatingEvent setCreatingEvent={setCreatingEvent} creatingEventDay={creatingEventDay} />
    if (eventId) return <EditingEvent setEventId={setEventId} eventId={eventId} events={events} />

    return (
        <div className="calendar-page">
            <div className='page-header'>
                <h1>Calendar</h1>
            </div>
            <div className='create-event' onClick={() => setCreatingEvent(true)}>Create Event</div>
            <Calendar events={filteredEvents} setCreatingEventDay={setCreatingEventDay} setCreatingEvent={setCreatingEvent} setEventId={setEventId} setGroupCriteria={setGroupCriteria} />
        </div>
    )
}