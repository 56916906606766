import './nav-bar.scss';
import BonsaiLogo from '../../../Assets/Images/bonsai-art-logo.svg'

const navItems = ['Groups', 'Members', 'Calendar', 'Applications'];

export default function NavBar({ setTab, tab }) {
    return (
        <div className="nav-bar">
            <img src={BonsaiLogo} className="logo" />
            <span className="title">Art of Bonsai Club</span>
            {navItems.map((item) => {
                const id = item.toLowerCase();
                return (
                    <div
                        key={id}
                        className={tab === id ? "selected" : "nav-item"} onClick={() => setTab(id)}>
                        <span className="nav-item-title">
                            {item}
                        </span>
                    </div>
                );
            })}
        </div >
    );
}