import './creating-event.scss';
import DateTimePicker from 'react-datetime-picker';

// import react stuff
import React, { useState, useEffect, useContext } from 'react';

// import the rest client
import { RestClientContext } from '../../../../../Contexts/rest_client.js';

export default function CreatingEvent({ setCreatingEvent, creatingEventDay }) {

    const { restClient } = useContext(RestClientContext);

    const [eventName, setEventName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventLocation, setEventLocation] = useState('');
    const [eventGroup, setEventGroup] = useState('');
    const [eventType, setEventType] = useState('');
    const [date, setDate] = useState(creatingEventDay);
    const [endDate, setEndDate] = useState(creatingEventDay);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await restClient.get('/Groups/GetGroups', {}, true);
            if (res.ok) setGroups(res.data);
        })();
    }, [restClient]);

    const createEvent = async () => {

        const body = {
            dateTitle: eventName,
            startTime: date.getTime(),
            endTime: endDate.getTime(),
            dateType: eventType,
            dateDescription: eventDescription,
            dateLocation: eventLocation,
            rsvp: false,
        }

        if (eventGroup !== '') body.dateGroup = eventGroup;

        // log body
        console.log(body);

        const result = await restClient.post('/Dates/CreateDate', body, true);
        if (result.ok) {
            setCreatingEvent(false);
        }
    }


    return (
        <div className="creating-event">
            <div className='exit' onClick={() => { setCreatingEvent(false); }}>Exit</div>
            <div className="input">
                <div className="text">Event Name</div>
                <input type="text" onChange={(e) => setEventName(e.target.value)} />
            </div>
            <div className="input">
                <div className="text">Event Description</div>
                <input type="text" onChange={(e) => setEventDescription(e.target.value)} />
            </div>
            <div className="input">
                <div className="text">Event Location</div>
                <input type="text" onChange={(e) => setEventLocation(e.target.value)} />
            </div>
            <div className="input">
                <div className="text">Event Group</div>
                <select onChange={(e) => setEventGroup(e.target.value)} value={eventGroup} className="input-selection">
                    <option value="">None</option>
                    {groups.map(group => <option key={group._id} value={group._id}>{group.name}</option>)}
                </select>
            </div>
            <div className="input">
                <div className="text">Event Type</div>
                <input type="text" onChange={(e) => setEventType(e.target.value)} />
            </div>
            <div className="input">
                <div className="text">Start Date</div>
            </div>
            <div className="time-input">
                <DateTimePicker disableClock={true} onChange={setDate} value={date} />
            </div>
            <div className="input">
                <div className="text">End Date</div>
            </div>
            <div className="time-input">
                <DateTimePicker disableClock={true} onChange={setEndDate} value={endDate} />
            </div>
            <div className="submit-button" onClick={createEvent}>
                Submit
            </div>
        </div>
    )

}