import './creating-group.scss';

// import react useStates
import React, { useState, useContext, useEffect } from 'react';


// import rest client
import { RestClientContext } from '../../../../../Contexts/rest_client.js';

// make a function for an input box with text above it
const InputBox = ({ text, value, setValue }) => {
    return (
        <div className="input-box">
            <div className="text">{text}</div>
            <input type="text" value={value} onChange={(e) => setValue(e.target.value)} />
        </div>
    )
}

// create a basic checkbox
const CheckBox = ({ text, value, setValue }) => {
    function handleCheck(e) {
        setValue(e.target.checked);
    }
    return (
        <div className="check-box">
            <div className="text">{text}</div>
            <input type="checkbox" checked={value} onChange={handleCheck} />
        </div>
    )
}


export default function CreatingGroup({ setCreatingGroup }) {
    const { restClient } = useContext(RestClientContext);

    const [groupName, setGroupName] = useState('');
    const [openApplications, setOpenApplications] = useState(false);
    const [groupLead, setGroupLead] = useState('63a208dd95d370d6d5b13348');

    const [members, setMembers] = useState([]);

    useEffect(() => {
        (async () => {
            if (!restClient.user) return;
            const result = await restClient.get('/Members/GetMembers', {}, true);
            if (result.ok) setMembers(result.data);
        })();
    }, [restClient]);

    const createGroup = async () => {
        const result = await restClient.post('/Groups/CreateGroup', {
            name: groupName,
            leadMember: groupLead,
            openApplications: openApplications
        }, true);
        if (result.ok) {
            setCreatingGroup(false);
        }
    }

    return (
        <div className="creating-group">
            <div className="top">
                <div className='exit' onClick={() => setCreatingGroup(false)}>Exit</div>
            </div>
            <div className="bottom">
                <InputBox text="Group Name" value={groupName} setValue={setGroupName} />
                <div className="text">Group Lead</div>
                <select value={groupLead} onChange={(e) => setGroupLead(e.target.value)} className="input-selection">
                    {members.map(member => <option value={member._id}>{`${member.firstName} ${member.lastName}`}</option>)}
                </select>
                <CheckBox text="Open Applications" value={openApplications} setValue={setOpenApplications} />
                <div className="create-group" onClick={createGroup}>Create Group</div>
            </div>
        </div>
    )
}
