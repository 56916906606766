import './content.scss'

// import tabs
import Groups from '../Tabs/Groups/groups.js';
import Members from '../Tabs/Members/members.js';
import Calendar from '../Tabs/Calendar/calendar.js';
import Applications from '../Tabs/Applications/applications.js';

export default function Content({ tab }) {
    return (
        <div className="content-page">
            <div className="gradient-wave"></div>
            <div className='content-box'>
                {tab === 'groups' ? <Groups /> : <></>}
                {tab === 'members' ? <Members /> : <></>}
                {tab === 'calendar' ? <Calendar /> : <></>}
                {tab === 'applications' ? <Applications /> : <></>}
            </div>
        </div>
    )
}