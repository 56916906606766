import './editing-group.scss';

// import react useStates
import React, { useState, useContext, useEffect } from 'react';


// import rest client
import { RestClientContext } from '../../../../../Contexts/rest_client.js';

// make a function for an input box with text above it
const InputBox = ({ text, value, setValue }) => {
    return (
        <div className="input-box">
            <div className="text">{text}</div>
            <input type="text" value={value} onChange={(e) => setValue(e.target.value)} />
        </div>
    )
}

// create a basic checkbox
const CheckBox = ({ text, value, setValue }) => {
    function handleCheck(e) {
        setValue(e.target.checked);
    }
    return (
        <div className="check-box">
            <div className="text">{text}</div>
            <input type="checkbox" checked={value} onChange={handleCheck} />
        </div>
    )
}

export default function CreatingGroup({ setEditingGroup, groupId, setGroupId, groups }) {
    const { restClient } = useContext(RestClientContext);

    const group = groups.find(group => group._id === groupId);

    const [groupName, setGroupName] = useState(group.name);
    const [openApplications, setOpenApplications] = useState(group.openApplications);
    const [groupLead, setGroupLead] = useState(group.lead);
    const [active, setActive] = useState(group.active);



    const [members, setMembers] = useState([]);

    useEffect(() => {
        (async () => {
            if (!restClient.user) return;
            const result = await restClient.get('/Members/GetMembers', {}, true);
            if (result.ok) setMembers(result.data);
        })();
    }, []);



    const editGroup = async () => {
        const result = await restClient.post('/Groups/EditGroup', {
            groupId,
            name: groupName,
            leadId: groupLead,
            openApplications: openApplications,
            active: active
        }, true);
        if (result.ok) {
            setEditingGroup(false);
            setGroupId('');
        }
    }

    const deleteGroup = async () => {
        const result = await restClient.post('/Groups/DeleteGroup', {
            groupId
        }, true);
        if (result.ok) {
            setEditingGroup(false);
            setGroupId('');
        }
    }

    // filter the members to only include members in the group
    const membersInGroup = members.filter(member => member.group === groupId);

    // create an email string of all the members
    const emailString = membersInGroup.map(member => member.email).join(',');
    // create a link to send an email to all the members with no subject or body
    const emailLink = `mailto:${emailString}?subject=&body=`;

    return (
        <div className="editing-group">
            <div className="top">
                <div className='exit' onClick={() => { setEditingGroup(false); setGroupId(''); }}>Exit</div>
                <h1>{groupName}</h1>
                <div className="stats">{membersInGroup.length} Members</div>
                <a href={emailLink}>
                    <div className="stats-button">
                        Send Email To Members
                    </div>
                </a>
            </div>
            <div className="bottom">
                <div onClick={() => deleteGroup()} className="delete">Delete Group</div>
                <InputBox text="Group Name" value={groupName} setValue={setGroupName} />
                <div className="text">Group Lead</div>
                <select value={groupLead} onChange={(e) => setGroupLead(e.target.value)} className="input-selection">
                    {members.map(member => <option value={member._id}>{`${member.firstName} ${member.lastName}`}</option>)}
                </select>
                <CheckBox text="Open Applications" value={openApplications} setValue={setOpenApplications} />
                <CheckBox text="Active" value={active} setValue={setActive} />
                <div className="create-group" onClick={editGroup}>Update Group</div>
            </div>
        </div>
    )
}
