import './editing-event.scss';
import DateTimePicker from 'react-datetime-picker';

// import react stuff
import React, { useState, useEffect, useContext } from 'react';

// import the rest client
import { RestClientContext } from '../../../../../Contexts/rest_client.js';

export default function EditingEvent({ eventId, setEventId, events }) {

    const { restClient } = useContext(RestClientContext);

    // find our event
    const editingEvent = events.find(event => event._id === eventId);

    const [eventName, setEventName] = useState(editingEvent.dateTitle);
    const [eventDescription, setEventDescription] = useState(editingEvent.dateDescription);
    const [eventLocation, setEventLocation] = useState(editingEvent.dateLocation);
    const [eventGroup, setEventGroup] = useState(editingEvent.dateGroup);
    const [eventType, setEventType] = useState(editingEvent.dateType);
    const [date, setDate] = useState(new Date(editingEvent.startTime));
    const [endDate, setEndDate] = useState(new Date(editingEvent.endTime));
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await restClient.get('/Groups/GetGroups', {}, true);
            if (res.ok) setGroups(res.data);
        })();
    }, [restClient]);

    const editEvent = async () => {

        const body = {
            dateId: eventId,
            dateTitle: eventName,
            startTime: date.getTime(),
            endTime: endDate.getTime(),
            dateType: eventType,
            dateDescription: eventDescription,
            dateLocation: eventLocation,
            rsvp: false,
        }

        if (eventGroup !== '') body.dateGroup = eventGroup;

        const result = await restClient.post('/Dates/EditDate', body, true);
        if (result.ok) {
            setEventId(null);
        }
    }

    const deleteDate = async () => {
        const result = await restClient.post('/Dates/DeleteDate', { dateId: eventId }, true);
        if (result.ok) {
            setEventId(null);
        }
    }


    return (
        <div className="editing-event">

            <div className='exit' onClick={() => { setEventId(null); }}>Exit</div>
            <div onClick={deleteDate} className="delete">Delete Date</div>

            <div className="input">
                <div className="text">{editEvent._id}</div>
            </div>
            <div className="input">
                <div className="text">Event Name</div>
                <input type="text" onChange={(e) => setEventName(e.target.value)} value={eventName} />
            </div>
            <div className="input">
                <div className="text">Event Description</div>
                <input type="text" onChange={(e) => setEventDescription(e.target.value)} value={eventDescription} />
            </div>
            <div className="input">
                <div className="text">Event Location</div>
                <input type="text" onChange={(e) => setEventLocation(e.target.value)} value={eventLocation} />
            </div>
            <div className="input">
                <div className="text">Event Group</div>
                <select onChange={(e) => setEventGroup(e.target.value)} value={eventGroup} className="input-selection">
                    <option value="">None</option>
                    {groups.map(group => <option key={group._id} value={group._id}>{group.name}</option>)}
                </select>
            </div>
            <div className="input">
                <div className="text">Event Type</div>
                <input type="text" onChange={(e) => setEventType(e.target.value)} value={eventType} />
            </div>
            <div className="input">
                <div className="text">Start Date</div>
            </div>
            <div className="time-input">
                <DateTimePicker disableClock={true} onChange={setDate} value={date} />
            </div>
            <div className="input">
                <div className="text">End Date</div>
            </div>
            <div className="time-input">
                <DateTimePicker disableClock={true} onChange={setEndDate} value={endDate} />
            </div>
            <div className="submit-button" onClick={editEvent}>
                Submit
            </div>
        </div>
    )

}