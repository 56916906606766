import './dash.scss';

// import useState
import React, { useState, useContext } from 'react';

// import the rest client
import { RestClientContext } from '../Contexts/rest_client.js';

// import Login component
import Login from './Login/login.js';

// import NavBar component
import NavBar from './Components/NavBar/nav-bar.js';

// import Content component
import Content from './Components/Content/content.js';



export default function Dash() {
    const { restClient } = useContext(RestClientContext);

    const [tab, setTab] = useState('groups');

    if (!restClient.user) {
        console.log('no user');
        return <Login />;
    }

    return (
        <div className='dash-page'>
            <NavBar setTab={setTab} tab={tab} />
            <Content tab={tab} />
        </div>
    );
}