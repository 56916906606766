import './groups.scss'

// import the rest client
import { RestClientContext } from '../../../../Contexts/rest_client.js';

// import useState
import React, { useState, useContext, useEffect } from 'react';

// import the creating/editing group components
import CreatingGroup from './CreatingGroup/creating-group.js';
import EditingGroup from './EditingGroup/editing-group.js';

export default function Groups() {
    const { restClient } = useContext(RestClientContext);


    const [groups, setGroups] = useState([]);

    const [creatingGroup, setCreatingGroup] = useState(false);
    const [editingGroup, setEditingGroup] = useState(false);

    const [groupId, setGroupId] = useState('');

    useEffect(() => {
        (async () => {
            if (!restClient.user) return;
            const result = await restClient.get('/Groups/GetGroups', {});
            if (result.ok) setGroups(result.data);
        })();
    }, [restClient, creatingGroup, editingGroup]);


    if (creatingGroup) return <CreatingGroup setCreatingGroup={setCreatingGroup} />
    if (editingGroup) return <EditingGroup setEditingGroup={setEditingGroup} groupId={groupId} setGroupId={setGroupId} groups={groups} />

    return (
        <div className="groups">
            <div className='page-header'>
                <h1>Groups</h1>
            </div>
            <div className="groups-list">
                <div className='create-group' onClick={(e) => { setCreatingGroup(!creatingGroup) }}>Create Group</div>
                {groups.map((group) => {
                    return (
                        <div className="group">
                            <div className="group-name" onClick={() => { setEditingGroup(true); setGroupId(group._id); }} key={group._id}>{group.name}</div>
                        </div>
                    )
                }
                )}
            </div>
        </div>
    )
}
