import './home.scss'

import BonsaiLogo from '../Assets/Images/bonsai-art-logo.svg'

export default function Home() {
    return (
        <div className="home">
            <meta name="viewport" content="width=device-width, initial-scale=0.6">
            </meta>
            <div className="topbar">
                <div className="logo">
                    <img src={BonsaiLogo} alt="logo" />
                    <div className="title">The Art of Bonsai Club</div>
                </div>
                <div className='topbar-buttons'>
                    <a className='button' href="/schedule">
                        Schedule
                    </a>
                    <a className='button' href="https://discord.gg/XR8eWeqWbv">
                        Discord
                    </a>
                    <a className='button' href="https://boilerlink.purdue.edu/organization/abc">
                        BoilerLink
                    </a>
                    <a className='button' href="https://www.instagram.com/purduebonsai/">
                        Instagram
                    </a>
                    <a className='button' href="https://donate.tinytrees.net/">
                        Donate
                    </a>
                    <a className='button join' style={{ color: 'hotpink' }} href="/application">
                        Join
                    </a>
                </div>
            </div>
            <div className="section-1">
                <div className="section-1-title">
                    Beauty, Elegance, Peace
                </div>
                <div className="section-1-subtitle">
                    The Art of Bonsai is a club dedicated to the cultivation of bonsai trees. We are a community of bonsai enthusiasts who share a passion for the art of bonsai. We are a club that is open to all students at the University of Purdue.
                </div>
                <a className='button' href="/application">
                    Apply To Join
                </a>
            </div>
        </div>
    )
}