// import react
import React, { createContext, useState } from 'react';
// import crypto module
import sha256 from 'crypto-js/sha256';

class RestClient {
    constructor(state, setState) {
        this.baseURL = 'https://api.tinytrees.net';
        this.user = state;
        this.setUser = setState;
    }

    getTimeToken() {
        const currentTimestamp = Math.floor(Date.now() / 600000) * 600000;
        // hash out the valid temporary token
        const stringToHash = `${this.user.token}${this.user._id}${currentTimestamp}`

        const hash = sha256(stringToHash).toString();

        return hash;
    }

    async get(path) {
        const response = await fetch(this.baseURL + path);

        return await this.parseResponse(response);
    }

    async parseResponse(response) {
        const result = {
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
        }



        // check if response has json
        if (response.headers.get('Content-Type').includes('application/json')) {
            result.data = await response.json();
        } else {
            result.data = await response.text();
        }

        if (!result.ok) console.log(result.data);

        return result;
    }

    async post(path, data, auth = false) {

        const headers = {
            'Content-Type': 'application/json',
        }

        if (auth) {
            headers["Authorization"] = this.getTimeToken();
            headers["User-Id"] = this.user._id
            console.log(this.user._id)
        }

        const response = await fetch(this.baseURL + path, {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        });

        return await this.parseResponse(response);
    }
}

export const RestClientContext = createContext();

export default function RestClientProvider({ children }) {
    const [state, setState] = useState(null);
    const restClient = new RestClient(state, setState);

    return (
        <RestClientContext.Provider value={{ restClient }}>
            {children}
        </RestClientContext.Provider>
    )
}
