import './schedule.scss'

// import react and usestate
import React, { useState, useContext, useEffect } from 'react';

// import rest client
import { RestClientContext } from '../Contexts/rest_client.js';

// import calendar
import ImportCalendar from './ImportCalendar/import-calendar';


export default function Schedule() {

    const [groups, setGroups] = useState([]);

    const [selectedGroup, setSelectedGroup] = useState("0");

    const [events, setEvents] = useState([]);

    // selected date aka month and year but in one date
    const [selectedDate, setSelectedDate] = useState(new Date());

    const { restClient } = useContext(RestClientContext);

    useEffect(() => {
        (async () => {
            const res = await restClient.get('/Groups/GetGroups', {}, false)
            if (res.ok) setGroups(res.data);
        })();
        (async () => {
            const res = await restClient.get('/Dates/GetAllDates', {}, false)
            if (res.ok) setEvents(res.data);
            setSelectedGroup("0");
        })();
    }, [restClient]);

    // array of shortened weekday names
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // function for next and previous month
    const nextMonth = () => {
        setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1));
    }

    const prevMonth = () => {
        setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1));
    }

    // filter the events based on whga group is selected
    const filteredEvents = events.filter(event => {
        if (selectedGroup === "0" || !event.dateGroup) return true;
        return event.dateGroup === selectedGroup;
    });

    const [pop, setPopup] = useState(null);
    const popup = events.find(e => e._id === pop);

    // determine how many blank spaces we need to add at the beginning of the month
    const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getDay();
    const blanks = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
        blanks.push(<td className="empty"></td>);
    }


    return (

        <div className='schedule-page'>
            <meta name="viewport" content="width=device-width, initial-scale=0.5" />
            {
                popup ? <div className='popup'>
                    <div className='popup-content'>
                        <div className='popup-title'><div className='title'>Title</div> {popup.dateTitle}</div>
                        <div className='popup-description'><div className='title'>Description</div> {popup.dateDescription}</div>
                        <div className='popup-location'><div className='title'>Location</div> {popup.dateLocation}</div>
                        <div className='popup-group'><div className='title'>Group</div> {groups.find(g => g._id === popup.dateGroup) ? groups.find(g => g._id === popup.dateGroup).name : "No Group"}</div>
                        <div className='popup-date'><div className='title'>Time</div>From {new Date(popup.startTime).toLocaleString({})} to {new Date(popup.startTime).toLocaleString({})}</div>
                        <div className='popup-close' onClick={() => setPopup(null)}>Close</div>
                    </div>
                </div> : null
            }
            <div className='calendar-box'>
                <div className='topbar'>
                    <div className='left'>
                        <select className='group-select' value={selectedGroup} onChange={e => setSelectedGroup(e.target.value)}>
                            <option selected value="0">All Events</option>
                            {groups.map(group => {

                                if (!group.active) return null;
                                return <option value={group._id}>{group.name}</option>;
                            })}
                        </select>
                        <ImportCalendar dates={filteredEvents} />

                    </div>
                    <div className='right'>
                        <div className='selector' onClick={prevMonth}>Previous</div>
                        <div className='selector' onClick={nextMonth}>Next</div>
                        <div className='subtitle'>
                            {selectedDate.toLocaleString('default', { month: 'long' })} {selectedDate.getFullYear()}
                        </div>
                    </div>
                </div>
                <div className='meat'>
                    <div className='weekdays'>
                        {
                            weekdays.map(weekday =>
                                <div className='day'>
                                    <div className='weekday-name'>
                                        {weekday}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='days'>
                        {blanks}
                        {
                            Array.from({ length: new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate() }, (v, i) => i + 1).map(day => {
                                // get any events that are on this day and month/year using the filtered events
                                const eventsOnDay = filteredEvents.filter(event => {
                                    const eventDate = new Date(event.startTime);
                                    return eventDate.getDate() === day && eventDate.getMonth() === selectedDate.getMonth() && eventDate.getFullYear() === selectedDate.getFullYear();
                                });

                                // check if the day is today
                                const isToday = new Date().getDate() === day && new Date().getMonth() === selectedDate.getMonth() && new Date().getFullYear() === selectedDate.getFullYear();

                                // creat a new style with white text if the day is today
                                const dayStyle = isToday ? { color: 'hotpink', textDecoration: 'underline' } : {};


                                const style2 = {};

                                // determine if we add a border left/right
                                let left = false;
                                let right = false;



                                if (day === 1 && blanks.length > 0) left = true;
                                // if its not a saturday, add a border right
                                if (new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day).getDay() !== 6) right = true;

                                if (right) style2.borderRight = '1px solid rgb(61, 61, 61)';
                                if (left) style2.borderLeft = '1px solid rgb(61, 61, 61)';

                                return (<div className='day' style={style2}>
                                    <div className='day-number' style={dayStyle}>
                                        {day}
                                    </div>
                                    <div className='events'>
                                        {
                                            eventsOnDay.map(event => {

                                                // get the time of this event and format it to be like 1:00 PM
                                                const eventTime = new Date(event.startTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

                                                return (
                                                    <div className='event' onClick={() => setPopup(event._id)}>
                                                        <div className='event-name'>
                                                            {event.dateTitle}
                                                        </div>
                                                        <div className='event-time'>
                                                            {eventTime}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>)
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}