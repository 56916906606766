import './applications.scss';

// import rest client
import { RestClientContext } from '../../../../Contexts/rest_client.js';

// import useState
import React, { useState, useContext, useEffect } from 'react';


export default function Applications() {

    // get the rest client
    const { restClient } = useContext(RestClientContext);

    // get the applications
    const [applications, setApplications] = useState([]);

    // selected application
    const [selectedApplication, setSelectedApplication] = useState(null);

    // groups
    const [groups, setGroups] = useState([]);

    // get the applications
    useEffect(() => {
        (async () => {
            const res = await restClient.post('/Application/GetApplications', {}, true)
            if (res.ok) setApplications(res.data);
        })();
        (async () => {
            const res = await restClient.get('/Groups/GetGroups', {}, true)
            if (res.ok) setGroups(res.data);
        })();
    }, [restClient]);


    const denyApp = async (id) => {
        const res = await restClient.post('/Application/ApplicationAction', {
            applicationId: id,
            action: 'deny'
        }, true);
        if (res.ok) {
            const res = await restClient.post('/Application/GetApplications', {}, true)
            if (res.ok) setApplications(res.data);
        }
    }

    const acceptApp = async (id) => {
        const res = await restClient.post('/Application/ApplicationAction', {
            applicationId: id,
            action: 'accept'
        }, true);
        if (res.ok) {
            const res = await restClient.post('/Application/GetApplications', {}, true)
            if (res.ok) setApplications(res.data);
        }
    }


    return (
        <div className='applications-page'>

            <div className='page-header'>
                <h1>Applications</h1>
            </div>
            <div className='applications-list'>

                {
                    applications.length === 0 ? (
                        <div className='no-apps'>
                            No applications
                        </div>
                    ) : null
                }

                {applications.map((application, index) => {
                    if (application.accepted) return null;
                    const style = {}

                    // if this is the selected application
                    if (application._id === selectedApplication) {
                        // border-bottom: 2px solid rgb(89, 0, 255);
                        style.borderBottom = '2px solid rgb(89, 0, 255)';
                    }

                    // get the written out creation date for the application, example: september 15 2020 3:14pm
                    const creationDate = new Date(application.created);

                    const month = creationDate.toLocaleString('default', { month: 'long' });
                    const day = creationDate.getDate();

                    const year = creationDate.getFullYear();

                    const hours = creationDate.getHours();
                    const minutes = creationDate.getMinutes();

                    const ampm = hours >= 12 ? 'pm' : 'am';

                    const time = `${hours % 12}:${minutes} ${ampm}`;

                    const creationDateText = `${month} ${day} ${year}, ${time}`;

                    const group = groups.find(g => g._id === application.group);
                    return (
                        <div className='app-wrapper' style={style}>
                            <div className='application-list-application' onClick={() => setSelectedApplication(application._id)} key={index}>
                                <div className='application-name'>
                                    {`${application.firstName} ${application.lastName}`}
                                </div>

                            </div>
                            {
                                application._id === selectedApplication ? (
                                    <div className='app-content'>
                                        <div className='app-field'>
                                            <div className='app-label'>
                                                Name
                                            </div>
                                            <div className='app-entry'>
                                                {`${application.firstName} ${application.lastName}`}
                                            </div>
                                        </div>
                                        <div className='app-field'>
                                            <div className='app-label'>
                                                Email
                                            </div>
                                            <div className='app-entry'>
                                                {application.email}
                                            </div>
                                        </div>
                                        {
                                            application.phoneNumber ? (
                                                <div className='app-field'>
                                                    <div className='app-label'>
                                                        Phone Number
                                                    </div>
                                                    <div className='app-entry'>
                                                        {application.phoneNumber}
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            group ? (
                                                <div className='app-field'>
                                                    <div className='app-label'>
                                                        Group
                                                    </div>
                                                    <div className='app-entry'>
                                                        {group.name}
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        <div className='app-field'>
                                            <div className='app-label'>
                                                Reason
                                            </div>
                                            <div className='app-entry'>
                                                {application.applicationReason}
                                            </div>
                                        </div>
                                        <div className='app-field'>
                                            <div className='app-label'>
                                                Submitted
                                            </div>
                                            <div className='app-entry'>
                                                {creationDateText}
                                            </div>
                                        </div>

                                        <div className='text-button'>
                                            <div className='accept' onClick={() => acceptApp(application._id)}>
                                                Accept
                                            </div>
                                            <div className='deny' onClick={() => denyApp(application._id)}>
                                                Deny
                                            </div>
                                        </div>

                                    </div>
                                ) : null
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )

}
