import './member-editor.scss'

// import the rest client
import { RestClientContext } from '../../../../../Contexts/rest_client.js';

// import useState
import React, { useState, useContext, useEffect } from 'react';

export default function MemberEditor({ memberId, setMemberId, members }) {

    const { restClient } = useContext(RestClientContext);

    const member = members.find(member => member._id === memberId);

    const [firstName, setFirstName] = useState(member.firstName);
    const [lastName, setLastName] = useState(member.lastName);
    const [role, setRole] = useState(member.role);
    const [group, setGroup] = useState(member.group);
    const [email, setEmail] = useState(member.email);
    const [permissionLevel, setPermissionLevel] = useState(member.permissionLevel);
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(member.phoneNumber);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await restClient.get('/Groups/GetGroups', {}, true);
            if (res.ok) setGroups(res.data);
        })();
    }, [restClient]);


    const deleteMember = async () => {
        const result = await restClient.post('/Members/DeleteMember', { memberId }, true);
        if (result.ok) setMemberId(null);
    }

    // create a link to send an email to the member with no body or subject
    const emailLink = `mailto:${email}`;

    console.log('member', member)

    return (
        <div className="member-editor">
            <div className='exit' onClick={() => { setMemberId(null); }}>Exit</div>
            <div className="member-editor-form">
                <a href={emailLink}>
                    <div className='email-link'>Send Email</div>
                </a>

                <div className="member-editor-form-field">
                    <div className='label'>First Name</div>
                    <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div className="member-editor-form-field">
                    <div className='label'>Last Name</div>
                    <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div className="member-editor-form-field">
                    <div className='label'>Role</div>
                    <input type="text" value={role} onChange={(e) => setRole(e.target.value)} />
                </div>
                <div className="member-editor-form-field">
                    <div className='label'>Group</div>
                    <select value={group} onChange={(e) => setGroup(e.target.value)} className="input-selection">
                        {groups.map(g => <option value={g._id}>{g.name}</option>)}
                    </select>
                </div>
                <div className="member-editor-form-field">
                    <div className='label'>Permission Level</div>
                    <input type="text" value={permissionLevel} onChange={(e) => setPermissionLevel(e.target.value)} />
                </div>
                <div className="member-editor-form-field">
                    <div className='label'>Email</div>
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="member-editor-form-field">
                    <div className='label'>Phone Number</div>
                    <input type="text" autocomplete='off' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                </div>
                <div className="member-editor-form-field">
                    <div className='label' autocomplete='off'>Password</div>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="member-editor-form-field">
                    <div className='button' onClick={async () => {

                        const jsonToSend = {
                            memberId: memberId,
                            firstName,
                            lastName,
                            groupId: group,
                            role,
                            email,
                            permissionLevel,
                            phoneNumber: phoneNumber
                        };

                        if (password.length > 0) {
                            jsonToSend.password = password;
                        }

                        const result = await restClient.post('/Members/EditMember', jsonToSend, true);

                        if (result.ok) {
                            setMemberId(null);
                        }
                    }}>Update Member</div>
                    <div onClick={() => deleteMember()} className="delete">Delete Member</div>
                </div>
            </div>
        </div >

    )
}