import "./login.scss"

// import the rest client
import { useContext, useState } from 'react';
import { RestClientContext } from '../../Contexts/rest_client.js';

export default function Login() {

    const { restClient } = useContext(RestClientContext);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const submit = async () => {
        const result = await restClient.post('/Members/Login', {
            email,
            password
        })

        console.log(result.data);

        if (result.ok) restClient.setUser(result.data);
        else setError(result.data);
    }

    return (
        <div className="login-page">
            <div className="login">
                <div className="title">Login</div>
                <span>{error}</span>
                <div className="subtitle">Email</div>
                <input className="login-input" onChange={(e) => setEmail(e.target.value)} type="text" placeholder="email" />
                <div className="subtitle">Password</div>
                <input className="login-input" onChange={(e) => setPassword(e.target.value)} type="password" placeholder="password" />
                <button className="login-button" onClick={submit}>Submit</button>
            </div>
        </div>
    )
}