import './members.scss'

// import the rest client
import { RestClientContext } from '../../../../Contexts/rest_client.js';

// import useState
import React, { useState, useContext, useEffect } from 'react';

// import member editor
import MemberEditor from './MemberEditor/member-editor.js';

export default function Members() {

    const { restClient } = useContext(RestClientContext);

    const [members, setMembers] = useState([]);
    const [groups, setGroups] = useState([]);

    const [memberId, setMemberId] = useState(null);

    useEffect(() => {
        (async () => {
            if (!restClient.user) return;
            const result = await restClient.get('/Members/GetMembers', {}, true);
            if (result.ok) setMembers(result.data);
        })();
        (async () => {
            if (!restClient.user) return;
            const result = await restClient.get('/Groups/GetGroups', {}, true);
            if (result.ok) setGroups(result.data);
        })();
    }, [restClient, memberId]);

    if (memberId) return <MemberEditor memberId={memberId} setMemberId={setMemberId} members={members} />

    return (
        <div className="members">
            <div className='page-header'>
                <h1>{members.length} Members</h1>
            </div>
            <div className="members-list">
                {members.map((member) => {

                    const group = groups.find(g => g._id === member.group);
                    const groupName = group ? group.name : 'No Group';

                    return (
                        <div className="member" onClick={() => setMemberId(member._id)} key={member._id}>
                            <div className="member-name">{member.firstName} {member.lastName}</div>
                            <div className='member-group'>{groupName}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

